<template>
  <div v-for="(el, ind) in state.entrances" :key="ind" class="entranceItem pl-50 pr-35 dp-f ai-c m-0-a mb-26">
    <img :src="el.imgurl" class="w-44 h-44 mr-16">
    <div>{{ el.title }}</div>
    <oabutton class="ml-at" width='76' height='32' title="进入" CSStype=2 style="border-radius: 4px;"
      @buttonclick="enterClick(ind)"></oabutton>
  </div>
  <invitations ref="invitationsRef" @setStep="emit('setStep',$event)"/>
</template>
<script setup>
import { router_push_name } from "@/utils/server/router";
import invitations from "./invitations.vue"//法定代表人实名验证弹框
import { ref,unref,reactive, defineEmits } from "vue";
const emit = defineEmits(['Emit','setStep'])
const invitationsRef= ref()
const state = reactive({
  entrances: [
    { title: '对公账户验证', imgurl: require('@/assets/img/realname/type0.png') },
    { title: '法定代表人实名验证', imgurl: require('@/assets/img/realname/type1.png') },
  ]
});
// 点击
const enterClick = (ind) => {
  if (ind == 1) {
    unref(invitationsRef).show()
  } else {
    emit('Emit', ind)
  }
}
</script>
<style lang="scss" scoped >
.entranceItem {
  width: 800px;
  height: 110px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(154, 154, 154, 0.22);
  border-radius: 8px;
}
</style>