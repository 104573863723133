<template>
  <div :class="'entrancebox' + state.code">
    <entrance @Emit="entranceEmit" v-if="state.code==0" @setStep="emit('setStep',$event)"/>
    <corporate v-else-if="state.code==1" @setStep="emit('setStep',$event)"/>
  </div>
</template>
<script setup>
import { reactive, ref, unref, defineEmits } from "vue";
import { useStore } from "vuex";
import entrance from "./authorization/entrance.vue"//入口
import corporate from "./authorization/corporate.vue"//对公打款
const emit = defineEmits(['setStep'])
const store = useStore();
const state = reactive({
  code: 0,//0 入口 1 对公账户验证
});
if(store.state.realName.companyInfo&&store.state.realName.companyInfo.authenticationState==4){
  state.code=1
}
const entranceEmit=(ind)=>{
  if(ind==0){
    state.code=1
  }
}
</script>
<style lang="scss" scoped >
.entrancebox0 {
  padding-top: 60px;
}

.entrancebox1 {
  padding-top: 40px;
}
</style>