<template>
	<div class="corporate allbox pb-20">
		<div class="dp-f mb-16 title">
			对公账户转账核验
			<statusIcon class="ml-7" title="转账成功，验证中" CSStype="1" />
		</div>
		<div class="introduce mb-20">银联已向您单位的对公账户发起了一笔转账，请在下方填写交易金额和交易摘要的6位数字，提交完成核验；</div>
		<formList ref="formListRef1" :labelWidth="150" labelPosition="left" class="mb-20" :FormDatas="state.FormDatas" />		
		<div class="dp-f mb-16 title">
			填写转账信息
		</div>
		<formList ref="formListRef2" :labelWidth="150" labelPosition="left" class="mb-20" :FormDatas="state.FormDatas2"
			@submit="submit2" />
		<oabutton v-model:loading="state.loading" @buttonclick="unref(formListRef2).submitForm()" class="mb-20 jc-c" width='106'
			height='48' title="确定" CSStype=2 style="border-radius: 4px;font-size: 16px;" />
	</div>
</template>
<script setup>
// 认证状态0：基本信息认证中 1：基本信息认证完成 2：认证授权公函认证中3：认证授权公函认证完成4：对公账户验证中5：对公账户验证完成6：全部认证完成7：已撤销 8认证失败
import { reactive, ref, unref, getCurrentInstance, nextTick, defineEmits } from "vue";
import { handleMessage } from "@/utils/server/confirm.js"
import statusIcon from "@/components/layout/parts/statusIcon.vue"
import formList from '../../formList.vue'//form 表单组件
import { selectById } from "@/utils/server/getdata.js"
import { useStore } from "vuex";
import { httpToken } from "@/utils/request";
const formListRef1 = ref()//对公账户验证表单ref
const formListRef2 = ref()//回填汇款金额验证表单ref
const instance = getCurrentInstance();//强制刷新
const emit = defineEmits(['setStep'])
const store = useStore();
const state = reactive({
	authenticationState: 1,//认证状态
	authRecordId: 0,
	companyInfo: '',
	loading: false,//loading 显示
	status: 0,//当前操作状态
	FormDatas: [
		{ label: '对公账户名：', type: 1, key: 'corporateAccountName', default: '', rules: [{ required: true, message: '请填写对公账户户名' }] },
		{ label: '对公账户账号：', type: 4, key: 'corporateAccountNo', default: '', rules: [{ required: true, message: '请填写对公账户账号' }] },
		{ label: '开户行：', type: 1, key: 'depositBank', default: '', rules: [] },
		{ label: '交易时间：', type: 1, key: 'transTime', default: '', rules: [] },
	],//当前表单数据
	FormDatas2: [
		{ label: '汇款金额（元）：', type: 3, key: 'transAmount', default: '', rules: [{ required: true, message: '请输入汇款金额' }] },
		{ label: '交易摘要：', type: 4, key: 'transCode', default: '', rules: [{ required: true, message: '请输入交易摘要,6位数字' }] },
	]
});

// 对公账户验证提交
const submit2 = (el) => {
	if (el) {
		state.loading = true
		httpToken({
			method: "post",
			url: '/admin/certification/enterpriseThree',
			data: {
				id: state.authRecordId, 
				corporateAccountName: unref(formListRef1).formModel.corporateAccountName,//对公账户户名：
				corporateAccountNo: unref(formListRef1).formModel.corporateAccountNo,//对公账户账号：
				depositBank: unref(formListRef1).formModel.depositBank,//开户行支行：
				companyId: state.companyInfo.companyId,
				transAmount: unref(formListRef2).formModel.transAmount,
				transCode: unref(formListRef2).formModel.transCode			}
		}).then((res) => {
			selectById(res.data,2).then(res2 => {
				// state.loading = false
				// 进入上传资料
				emit('setStep', 3)
				handleMessage('对公账户转账核验成功', 'success')
			})
		}).catch(err => {

		})
		state.loading = false
	}
}
const resetData = ((res, type) => {
	state.authenticationState = res.authenticationState,//认证状态
	state.authRecordId = res.id
	state.companyInfo = res
	if (state.authenticationState >= 4) {
		nextTick(() => {
			// unref(formListRef1).formModel.corporateAccountName = res.companyName
			// unref(formListRef1).formModel.corporateAccountNo = res.corporateAccountNo
			// unref(formListRef1).formModel.depositBank = res.depositBank
			state.FormDatas[0].disabled = true
			state.FormDatas[0].default = res.companyName 
			state.FormDatas[1].disabled = true
			state.FormDatas[1].default = res.corporateAccountNo 
			state.FormDatas[2].disabled = true
			state.FormDatas[2].default = res.depositBank 
			state.FormDatas[3].disabled = true
			state.FormDatas[3].default = res.transTime

		})
	}
	if (type) {
		instance.proxy.$forceUpdate();//强制刷新
	}
})
resetData(store.state.realName.companyInfo)
</script>
<style lang="scss" scoped >
.corporate {
	padding-top: 60px;
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #333333;

	.introduce {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.download {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #1890FF;
	}
}

.allbox {
	width: 760px;
	margin: 0 auto;
	padding: 46px 40px;
	background: #FFFFFF;
	border-radius: 12px;
}
</style>